import styled, { css } from 'styled-components';
import { SectionWrapper, H2 } from 'styles/Typography.styled';
import { mediaQueries } from 'shared/breakpoints';

export const Container = styled(SectionWrapper)(
    ({ theme: { colors } }) => css`
        flex-direction: column;
        ${mediaQueries.md} {
            width: 100%;
            padding: 0 14px 56px;
            background-color: ${colors.primaryBlue60};
        }
    `,
);

export const SectionTitle = styled(H2)(
    ({ theme: { colors } }) => css`
        margin: 80px 0 32px;

        ${mediaQueries.md} {
            margin: 0;
            background-color: ${colors.primaryBlue60};
            padding: 56px 32px 32px;
            text-align: center;
        }
    `,
);
