import { navigate } from 'gatsby';
import React from 'react';
import { useResizeColumns, useSortBy, useTable } from 'react-table';
import { columns, orderByFn } from 'shared/columns';
import {
    TableNotSortIcon,
    TableSortDownIcon,
    TableSortUpIcon,
} from 'shared/svg/tableSortIcon';
import {
    FavoritesTableWrapper,
    SortHeaderWrapper,
    TableWrapper,
} from 'styles/Table.styled';
import { SingleFlatType } from 'types/CommonTypes';

interface FavoritesTableProps {
    data: SingleFlatType[];
    investmentSlug: string;
}

export const FavoritesTable = ({
    data,
    investmentSlug,
}: FavoritesTableProps) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable(
            {
                columns,
                data,
                initialState: {
                    sortBy: [
                        {
                            id: 'flatStatus',
                            desc: true,
                        },
                    ],
                },
                disableMultiSort: true,
                orderByFn,
            } as any,
            useResizeColumns,
            useSortBy,
        );

    return (
        <FavoritesTableWrapper>
            <TableWrapper>
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups?.map((headerGroup, index) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers?.map((column) => (
                                    <th
                                        {...column.getHeaderProps(
                                            column.getSortByToggleProps(),
                                        )}
                                    >
                                        <SortHeaderWrapper>
                                            {column.render('Header')}
                                            <span>
                                                {column.isSorted ? (
                                                    column.isSortedDesc ? (
                                                        <TableSortDownIcon />
                                                    ) : (
                                                        <TableSortUpIcon />
                                                    )
                                                ) : (
                                                    <TableNotSortIcon />
                                                )}
                                            </span>
                                        </SortHeaderWrapper>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows?.map((row: any) => {
                            prepareRow(row);
                            return (
                                <tr
                                    {...row.getRowProps()}
                                    onClick={() =>
                                        navigate(
                                            '/' +
                                                investmentSlug +
                                                '/mieszkanie/' +
                                                row.original
                                                    .investmentBuilding +
                                                '/' +
                                                row.original.flatNumber,
                                        )
                                    }
                                >
                                    {row.cells?.map((cell: any) => {
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </TableWrapper>
        </FavoritesTableWrapper>
    );
};
